<template>
  <div class="container">
    <div class="page-header">
      <h1 class="mb-0">Roles & Permissions</h1>
    </div>

    <div class="d-flex flex-row align-items-center justify-content-between mb-3">
      <div>
        <span v-if="isFilterDirty || query" class="text-truncate">Results found {{numeral(meta.total).format('0,0')}}
          roles
        </span>
        <span v-else class="text-truncate">Showing {{numeral(items.length).format('0,0')}} of
          {{numeral(meta.total).format('0,0')}} total roles
        </span>
      </div>
      <create-role-modal @submit="refresh"/>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table class="table table-hover table-outline table-vcenter card-table">
                <thead>
                  <tr>
                    <th>Roles</th>
                    <th>No. of Permissions</th>
                    <th>No. of Users</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    class="cursor-pointer"
                    @click="()=>navigate(item.id)">
                    <td class="text-capitalize">{{item.name}}</td>
                    <td>{{item.permissions_count}}</td>
                    <td>{{item.users_count}}</td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="100%" class="text-center">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage">
          </pagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {roles} from '@/services';
import CreateRoleModal from './components/CreateRoleModal.vue';

export default {
  name: 'RolesAndPermissions',
  components: {
    CreateRoleModal,
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      isFilterDirty: false,
      listLoading: false,
    };
  },
  methods: {
    async fetchData(page) {
      return roles.get({
        params: {
          limit: this.limit,
          page,
          with_count: 'permissions,users',
          query: this.query,
        },
      });
    },
    navigate(id) {
      this.$router.push(`/roles-permissions/${id}`);
    },
  },
};
</script>
