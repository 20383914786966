<template>
  <div>
    <button
      type="button"
      class="btn btn-primary"
      data-test="button-create-role"
      @click.self.prevent="showThis">
      Create role
    </button>
    <b-modal
      v-model="showModel"
      centered
      data-test="modal-create-role"
      no-close-on-backdrop
      title="Create New Role"
      size="md"
      hide-footer
      @hide="resetForm">
      <template slot="modal-header-close">&nbsp;</template>
      <dimmer :active="loading">
        <form ref="form" @submit.prevent="submit">
          <div class="row">
            <div class="col">
              <div class="card-body">
                <div class="form-group row d-flex">
                  <label class="col-sm-4 col-form-label text-right font-weight-bold">
                    Name
                  </label>
                  <div class="col-sm-8">
                    <input
                      v-model="form.name"
                      ref="roleNameInput"
                      type="text"
                      data-test="input-role-name"
                      required
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex py-2">
                <button type="submit" class="btn btn-primary ml-auto">
                  Create
                </button>
              </div>
            </div>
          </div>
        </form>
      </dimmer>
    </b-modal>
  </div>
</template>

<script>
import {roles} from '@/services';

export default {
  name: 'CreateRoleModal',
  data() {
    return {
      loading: false,
      showModel: false,
      form: {
        name: null,
      },
    };
  },
  methods: {
    showThis() {
      this.showModel = true;
      setTimeout(() => {
        this.$refs.roleNameInput.focus();
      });
    },
    async submit() {
      try {
        this.loading = true;
        const role = this.startCase(this.form.name.trim());
        const {item} = await roles.post({
          name: role,
        });
        // close the popup
        this.showModel = false;
        this.$toasted.success(`New role "${role}" is created successfully!`);
        // reset form
        this.resetForm();
        // route to edit page
        this.$router.push(`/roles-permissions/${item.role.id}`);
      }
      catch (error) {
        console.error(error);
        this.$toasted.error(`Something went wrong, ${Object.values(error.response?.data?.errors || []).join(',')}`);
      }
      finally {
        this.loading = false;
      }
    },
    resetForm() {
      // reset the form data
      this.form = {
        name: null,
      };
    },
  },
};
</script>
